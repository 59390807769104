import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons';
import axios from '../../config/axios';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import { scrollToElementWithId, formatUTCDateWithOffset, isMobileDevice, StorageUtils } from '../../config/functions';
import { EventsTypeRes } from '../../Models/ResponseModels/Events';
import LivePageAgendaItem from './LivePageAgendaItem';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import LivePageSectionHeader from './LivePageSectionTitle';

// Info:
// Events are all visible. Evidence the one which is actually active


interface IProps {
    userData: MembersTypeRes.IMember,        // TODO: remove | null
    groupData: MembersTypeRes.IGroupAssociation | null,
}

let totalEvents: EventsTypeRes.IEvent[] = [];               // All events
let visibleEvents: EventsTypeRes.IEvent[] = [];             // Events to be displayed (variable used for updating active event coming from websocket)

const LivePageAgenda = ({ userData, groupData }: IProps) => {



    const dateNow = new Date()
    const initDay = dateNow.getFullYear() + '-' + ("0" + (dateNow.getMonth() + 1)).slice(-2) + '-' + ("0" + dateNow.getDate()).slice(-2);
    // #IMPORTANT: Using context to have access to global settings
    const { language } = useContext(ContextAppSettings);

    const [timezone, setTimezone] = useState<string>(window.location.href.indexOf('china') >= 0 ? 'apac' : 'emea');
    const [day, setDay] = useState<string>(initDay);
    const [events, setEvents] = useState<EventsTypeRes.IEvent[]>([]);

    const parentId = 'B75912E2-DF54-4BCB-86BF-8A5FD623218A';

    useEffect(() => {
        loadAgendaContents();

        window.addEventListener('enableEvent', enableEvent, false);
        window.addEventListener('disableEvent', disableEvent, false);

    }, [])

    const loadAgendaContents = () => {
        if (totalEvents.length > 0) {
            console.log("LivePageAgenda - childComponentsLoaded")
            window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
            loadFilteredAgendaContents();
        } else {
            axios.get('Events?ParentID=' + parentId + '&sortField=startDate&limit=999').then(res => {
                console.log("LivePageAgenda - childComponentsLoaded")
                window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
                const response = res.data;
                if (response.status === "success") {
                    totalEvents = response.data;
                    loadFilteredAgendaContents();
                }
            })
        }
    }

    const loadFilteredAgendaContents = () => {

        // const selectDay = document.getElementById("selectDay") as HTMLSelectElement;
        const selectDay = { value: '2024-12-03' }
        //Checking if day selector is found in page:
        if (selectDay != null) {
            let cachedValues = StorageUtils.GetItemValue("ActiveEvents");
            let activeEvents: EventsTypeRes.IEvent[] = cachedValues ? cachedValues : [];
            // bug mac / iOS: add time to avoid date changing to previous caused by timezone
            const day = new Date(selectDay.value + 'T12:00');
            const filterDay = day.getDate();
            const filterMonth = day.getMonth();
            const filterYear = day.getFullYear();
            // const newEvents = totalEvents.filter(event => {
            //     const eventStartDate = formatUTCDateWithOffset(new Date(event.startDate), 0);
            //     const eventDay = eventStartDate.getDate();
            //     const eventMonth = eventStartDate.getMonth();
            //     const eventYear = eventStartDate.getFullYear();
            //     return eventDay === filterDay && eventMonth === filterMonth && eventYear === filterYear;
            // })
            //     .map(x => {
            //         x.active = activeEvents.findIndex(y => y.eventID === x.eventID) > -1;
            //         return x;
            //     });
            const newEvents = totalEvents.map(x => {
                x.active = activeEvents.findIndex(y => y.eventID === x.eventID) > -1;
                return x;
            });
            setEvents(newEvents);
            setDay(day.getFullYear() + '-' + ("0" + (day.getMonth() + 1)).slice(-2) + '-' + ("0" + day.getDate()).slice(-2));
            visibleEvents = newEvents;
        }
    }


    const enableEvent = (e: any) => {
        if (e.detail) {
            const eventID = e.detail.eventID;
            setEventActiveState(eventID, true)
        }
    }

    const disableEvent = (e: any) => {
        if (e.detail) {
            const eventID = e.detail.eventID;
            setEventActiveState(eventID, false)
        }
    }

    const setEventActiveState = (eventID: string, value: boolean) => {
        totalEvents = totalEvents.map(event => {
            return { ...event, active: event.eventID === eventID ? value : event.active }
        })
        visibleEvents = visibleEvents.map(event => {
            return { ...event, active: event.eventID === eventID ? value : event.active }
        })
        StorageUtils.SetItem("ActiveEvents", totalEvents.filter(x => x.active));
        setEvents(visibleEvents)
    }

    if (events.length > 0) {
        const eventActive = events.filter(event => event.active === true);
        if (eventActive.length > 0) {
            setTimeout(() => {
                scrollToElementWithId(eventActive[0].eventID, -10, document.querySelector('.live-page-agenda-content'));
            }, 500);
        } else {
            setTimeout(() => {
                scrollToElementWithId(events[0].eventID, -10, document.querySelector('.live-page-agenda-content'));
            }, 500);
        }
    }

    return (
        <div className="live-page-agenda">
            <LivePageSectionHeader
                title={translations.agenda.header_title[language]}
                imgSource="assets/icons/agenda_icon.png"
            />
            {/* <div className="live-page-agenda-header"> */}
            {/* <h2 className="u-font-size-10 font-weight-bold text-white text-center mb-3">
                    { translations.agenda.header_subtitle[language] }
                </h2> */}
            {/* <div className="select_box">
                    <select className="form-control live-page-agenda-timezone" name="timezone" id="selectTimezone"
                        value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                        <option value="emea">Europe (CET)</option>
                        <option value="russia">Russia (RU)</option>
                    </select>
                </div> */}
            {/* <div className="select_box">
                    <select className="form-control live-page-agenda-day" name="day" id="selectDay"
                        value={day} onChange={() => loadFilteredAgendaContents()}>
                        <option value="2020-12-15">15. Dezember 2020</option>
                        <option value="2020-12-16">16. Dezember 2020</option>
                    </select>
                </div> */}
            {/* </div> */}
            {/* <h1 className="u-font-size-16 font-weight-bold text-center">AGENDA</h1> */}
            <div className="live-page-agenda-content-wrapper" style={{ position: 'relative' }}>
                <div className="live-page-agenda-content" style={{ position: 'relative' }}>

                    {/* FAKE */}
                    {/* <div id="1" className={"live-page-agenda-item"}>
                        <header className="live-page-agenda-item__header">
                            <p className="live-page-agenda-item__time u-font-size-8">6:00</p>
                        </header>
                        <h1 className="live-page-agenda-item__title u-font-size-10">
                            <span className="u-font-size-25">▸</span>
                            Countdown
                        </h1>
                    </div>
                    <div id="2" className={"live-page-agenda-item"}>
                        <header className="live-page-agenda-item__header">
                            <p className="live-page-agenda-item__time u-font-size-8">6:15</p>
                        </header>
                        <h1 className="live-page-agenda-item__title u-font-size-10">
                            <span className="u-font-size-25">▸</span>
                            Intro video
                        </h1>
                    </div>
                    <div id="3" className={"live-page-agenda-item"}>
                        <header className="live-page-agenda-item__header">
                            <p className="live-page-agenda-item__time u-font-size-8">6:30</p>
                        </header>
                        <h1 className="live-page-agenda-item__title u-font-size-10">
                            <span className="u-font-size-25">▸</span>
                            Team Speech
                        </h1>
                    </div>
                    <div id="4" className={"live-page-agenda-item active"}>
                        <header className="live-page-agenda-item__header">
                            <p className="live-page-agenda-item__time u-font-size-8">7:00</p>
                        </header>
                        <h1 className="live-page-agenda-item__title u-font-size-10">
                            <span className="u-font-size-25">▸</span>
                            Video and Discussion
                        </h1>
                    </div> */}

                    {
                        events.map(event => {
                            return <LivePageAgendaItem key={event.eventID} event={event} timezone={timezone} userData={userData} groupData={groupData} />
                        })
                    }
                </div>
                {isMobileDevice() ? <FontAwesomeIcon icon={faArrowsAltV} className="live-page-agenda-scrollicon" /> : null}
            </div>
        </div>
    )

}

export default LivePageAgenda;



