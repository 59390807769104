import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';

import { wsSend } from '../../config/functions';
import axios from '../../config/axios';
import { formatFileSize,saveLog } from '../../config/functions';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import { WebSocketTypeRes } from '../../Models/ResponseModels/WebSocketModels';
import MemberLog from '../../Models/Log/MemberLog';
import { ChatTypeRes } from '../../Models/ResponseModels/Chat';
import Button from '../_UI/Button/Button';

var CryptoJS = require("crypto-js");

interface IAttach {
    fileSize: string,
    fileName: string,
    fileType: string,
}

interface IQuote {
    messageId: string, 
    quotedUserName: string, 
    quotedUserLastName: string, 
    quoteContent: string
}

interface IProps {
    chatRoomID?: number,
    attachmentsFolder: number,
    quotedMessage: IQuote | null,
    websocket: WebSocket,
    showIsAtEvent?: boolean,
    isAtEvent?: boolean,
    liveWebinar?: boolean,                   // is page used to only show messages live?
    isAttachmentAvailable?: boolean,         // Can I send attachments? default value: true
    isBackstageChat?: boolean,
    setQuotedMessage: Function,
    onSubmit: Function
}

let messageTextarea: any;

const ChatTextarea = ({ chatRoomID = 0, attachmentsFolder, quotedMessage = null, websocket, showIsAtEvent = false, isAtEvent = false, liveWebinar = false, isAttachmentAvailable = true, isBackstageChat = false, setQuotedMessage, onSubmit }: IProps) => {

    const [attachments, setAttachments] = useState<IAttach[]>([]);
    const [messageValue, setMessageValue] = useState<string>('');
    const [isSenderNameHidden, hideSenderName] = useState<boolean>(!isBackstageChat);
    const [isSessionExpired, setSessionExpired] = useState<boolean>(false);

    const roomID = chatRoomID > 0 ? chatRoomID : parseInt(window.storageGetItemValue("roomID"));
    const { language } = useContext(ContextAppSettings);

    useEffect(() => {

        // Autosize #message-textarea based on content
        // http://www.jacklmoore.com/autosize/
        var textareaToAutosize:any = document.querySelector("#message-textarea");
        // Default value to update textarea when user send a message
        textareaToAutosize.val = "40px";
        
        // To simulate maxlength desktop behavior on android 
        var textareaMaxlength:any = document.querySelector("#message-textarea");
    
        textareaMaxlength.onkeyup = function(e:any) {
            var maxlg = 500;
            if(textareaMaxlength.value.length > maxlg) {
                textareaMaxlength.value = textareaMaxlength.value.substring(0, maxlg);
            }
        };

    }, []);

    const handleKeyUpEvent = (e: any) => {
        textAreaAdjust();
        //User hits ctrl+enter keys
        if (e.ctrlKey && e.keyCode == 13) {
            handleSendMessage();
        };
    }

    const textAreaAdjust = () => {
        var textarea: any = document.querySelector('#message-textarea');
        if(textarea && textarea.style){
            textarea.style.height = "1px";
            textarea.style.height = (textarea.scrollHeight)+"px";
        }
    } 

    const handleAttachChange = (e: any) => {
        var files = e.target.files;
        var numFiles = files.length;
        var attachments: IAttach[] = [];
        for(var i=0; i<numFiles; i++)
        {
            var size = files[i].size;
            attachments.push({
                fileSize: formatFileSize(size),
                fileName: files[i].name,
                fileType: files[i].type
            })
        }
        setAttachments(attachments);
    }

    const handleSendMessage = () => {

        var message_attach = $("#chat-main-container-"+roomID+" #chat-attachment");     // user message attach
        //if message is empty return false
        if(!messageValue && (message_attach.length < 1 || !message_attach.prop('files')[0])) {
            return false;
        }
        if(message_attach.length >= 1 && message_attach.prop('files')[0]) {
            uploadChatAttachment();	//calls sendMsgToDb() after uploading
        }
        else {

            axios.post('Chat/'+roomID+'/Message', {
                content: messageValue,
                anonymous: isSenderNameHidden,
                quotedMessage: quotedMessage ? quotedMessage.messageId : null,
                messageResources: []
            }).then(res => {

                if(res.data.status === "success"){
                    const message: ChatTypeRes.IChatMessage = res.data.data;
                    const newLog: MemberLog = new MemberLog("/", "SendMessage","EMEA Town Hall - 2024-12-03","ChatMessage","",message.message);

                    if(window.webSocket == null || window.webSocket.readyState === 3){
                        window.dispatchEvent(new CustomEvent('loadChatMessage', {"detail": { message: message.message }}));
                    } else {
                        const wsMessage: WebSocketTypeRes.IWsMessage = {
                            action: "Message",
                            roomID: roomID.toString(),
                            message: message
                        };
                        wsSend(wsMessage);
                    }

                    saveLog(newLog);
                    messageTextarea.focus();
                    setMessageValue('');
                }

            })
        }
        setQuotedMessage(null);
    }

    const uploadChatAttachment = async () => {
        var files = $("#chat-main-container-"+roomID+" #chat-attachment").prop('files');

        var messageResources:any = [];

        await window.asyncForEach(files, async (file:any, indexQuestion: number) => {
            var data = new FormData();
            data.append('AttachedFile', '');
            data.append('ResourceTypeID', '');
            data.append('ResourceCategoryID', attachmentsFolder.toString());
            data.append('Public', 'true');
            data.append('Published', 'true');
            data.append('IsExternal', 'false');
            data.append('Priority', '0');

            var filesize = file.size;
            var maximum_size = 10 * 1024 * 1024;
            if ( file.type.match(/^video/i) || file.type.match(/(?:.*powerpoint)|(?:.*presentation)/i) )
            {
                maximum_size = 30 * 1024 * 1024;
            }
            
            if(filesize > maximum_size) 
            {
                alert("Maximum filesize exceeded. The submitted file can't exceed "+ (maximum_size/1024/1024) +"MB");
                return false;
            }

            data.append('AttachedFile', file);
            const res = await axios.post('Resources', data);
            messageResources.push(res.data.data)
        });
    
        sendMsgToDb(messageResources);
        setAttachments([]);
    }

    const sendMsgToDb = (uploadResponse:any) => {

        var message_input:any = messageValue;
        var quoteId = $("#chat-main-container-"+roomID+" #msg-quoted").attr("data-messageid");

        //prepare json data (reflects backend class message)
        var msg:any = {
            content: message_input.val().replace(/(?:\r\n|\r|\n)/g, '<br>'),
            quotedMessage: quoteId,
            messageResources: [] as any
        };
    
        if(uploadResponse) 
        {
            msg.messageResources = uploadResponse;
        }

        if(showIsAtEvent) {
            if(isAtEvent) {
                msg.content = '<span class="location live">Live audience</span><br/>' + msg.content; 
            }
            else {
                msg.content = '<span class="location digital">Digial audience</span><br/>' + msg.content; 
            }
        }
        
        //send message to database
        axios.post("Chat/" + roomID + "/Message", msg).then(res => {
            var info = res.data;
            msg = info.data;
            //convert and send data to websocket
            if(info.status === "success")
            {
                websocket.send(JSON.stringify(msg));
            }
            else
            {
                alert("Error sending message\n Details: " + info.message);
            }
        }).catch(e => {
            console.log("ChatTextArea.tsx","Error in sending message to database:", e);
        })

        $("#chat-main-container-"+roomID+" #chat-attachment").val("");      //reset file input
        setMessageValue('');                                         //reset message input
        textAreaAdjust();
    }

    const deleteAttachments = () => {
        setAttachments([]);
        $("#chat-main-container-"+roomID+" #chat-attachment").val("");
    }

    const deleteQuote = () => {
        setQuotedMessage(null);
    }


    const quoteHTML = quotedMessage ? (
        <div id="msg-quoted" data-messageid={quotedMessage.messageId} data-name={quotedMessage.quotedUserName} data-lastname={quotedMessage.quotedUserLastName}>
        	In reply to
        	<span id="msg-quoted-user-name">{quotedMessage.quotedUserName + ' ' + quotedMessage.quotedUserLastName}</span>:
        	<span className="msg-quoted-message">
        	&quot;<span id="msg-quoted-message">{quotedMessage.quoteContent}</span>&quot;</span>
        </div>
    ) : null

    const attachmentsHTML = attachments.map((attach: IAttach) => {
        return(
            <div className="file-attachment" data-name={attach.fileName}>
                <span className="file-icon" data-type={attach.fileType}></span>
                <span className="file-name">{attach.fileName}</span>
                <span className="file-size">{attach.fileSize}</span>
                {/* TODO: single file delete */}
                {/* <button className="btn btn-delete-attachment" type="button">X</button> */}
            </div>
        )
    })

    const textAreaPlaceholder = translations.chat.textbox_placeholder[language]
    // var isDisabledUser = !window.userInRole("ChatPartecipant") && !window.userInRole("ChatAdmin") && !window.userInRole("ChatEditor");
    var isDisabledUser = window.storageGetItemValue("Auth-name") === "Backstage";

    return (
        <div className="user-panel" id={"user_panel_chat_"+roomID}>
            <div className={"user-panel-anonymous"+(isBackstageChat ? " invisible" : "")}>
                <div className="form-check">
                    <input 
                        type="checkbox" className="form-check-input" id={"user_anonym_chat_"+roomID} 
                        onChange={(e) => { hideSenderName(e.target.checked) }}
                        checked={isSenderNameHidden}
                    />
                    <label className="form-check-label" htmlFor={"user_anonym_chat_"+roomID}>
                        { translations.chat.check_anonym_message[language] }
                    </label>
                </div>
            </div>
            {/* render quoted message */}
            {
                quoteHTML !== null ?
                <div id="msg-quoted-container">
                    <div id="msg-quoted-content">
                        { quoteHTML }
                    </div>
                    <button className="btn btn-delete-quote" id="delete-current-quote" type="button" onClick={deleteQuote}>X</button>
                </div> : null
            }
            <div id="inputWithIcon">
                <textarea onKeyUp={handleKeyUpEvent} style={{ overflow: 'hidden' }}
                    name="message" id="message-textarea" placeholder={textAreaPlaceholder} maxLength={500}
                    ref={(el) => { messageTextarea = el }}
                    value={messageValue} onChange={(e) => { setMessageValue(e.target.value) }}
                >
                </textarea>
                <span className="glyphicon glyphicon-send" id="send-message"
                    onClick={handleSendMessage}></span>
            </div>
            {
                isAttachmentAvailable ?
                <label className="glyphicon glyphicon-paperclip" id="chat-attachment-btn">
                    <input type="file" id="chat-attachment"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    application/pdf, image/*, video/*" hidden multiple
                    onChange={handleAttachChange} />
                </label> :
                null
            }
            {/*<span className="glyphicon glyphicon-send" id="send-message"
                onClick={handleSendMessage}></span>*/}
                {/*
            <div className="user-panel__buttons">
                <Button type="submit"
                    withClass={["primary","small"]}
                    icon="faChevronRight"
                    iconPosition="right"
                    classes="interactive-page-form__submit mt-2 u-font-size-10"
                    clicked={handleSendMessage}
                >Send</Button>
            </div>    
*/}
            {/* render attachments */}
            {
                attachments.length > 0 ?
                <div id="msg-attachments-container">
                    <div id="msg-attachments-content">
                        {attachmentsHTML}
                    </div>
                    <button className="btn btn-default" id="delete-current-attachments" type="button" onClick={deleteAttachments}>X</button>
                </div> : null
            }
            {
                isDisabledUser ?
                <div className="box-hide-content">
                    <p className="u-font-size-16">You cannot interact with this content</p>
                </div> : 
                null
            }
            {
                isSessionExpired ?
                <div className="box-hide-content">
                    <p className="u-font-size-12">Session expired.<br></br>Please refresh the page and try again</p>
                </div> : 
                null
            }
        </div>
    )
}

export default ChatTextarea;