import React, { useContext, useEffect, useState } from 'react';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { showLoader, hideLoader } from '../../config/functions';

import LivePageVideo from '../../Components/LivePage/LivePageVideo';
import LivePageAgenda from '../../Components/LivePage/LivePageAgenda';
import LivePageDocuments from '../../Components/LivePage/LivePageDocuments';
import LivePagePolls from '../../Components/LivePage/LivePagePolls';
import LivePageQuestionChat from '../../Components/LivePage/LivePageQuestionChat';
import LivePageEventTitle from '../../Components/LivePage/LivePageEventTitle';
import axiosInstance from '../../config/axios';
import Confetti from '../../Components/Confetti/Confetti';
import LivePageSectionHeader from '../../Components/LivePage/LivePageSectionTitle';
import { translations } from '../../config/translations';
import { ContextAppSettings } from '../../config/context';


interface IProps {
  userData: MembersTypeRes.IMember | null,
  groupData: MembersTypeRes.IGroupAssociation | null,
}

const msTimeoutConfetti = 6000;
var myTimeout: any;
var childComponentsLoaded = 0;
var childComponentsToLoad = 5;

const LivePageTemplateThree = ({ userData, groupData }: IProps) => {

  const [isConfettiVisible, setConfettiVisible] = useState<boolean>(false);
  const [isConfettiHiding, setConfettiHiding] = useState<boolean>(false);
  const [isSurveyVisible, setSurveyVisible] = useState<boolean>(false);
  const { language } = useContext(ContextAppSettings);

  useEffect(() => {
    showLoader();
    axiosInstance.get('Contents/emea-town-hall-03-december-2024')
    window.addEventListener("ShowEffect", showEffect);
    setSurveyVisible(window.localStorage.getItem("showSurvey") === "true");
  }, []);

  // When all child components are loaded show the page:
  // - VIDEO
  // - CHAT
  // - AGENDA
  // - POLLS
  // - DOCS
  window.addEventListener('childComponentsLoaded', () => {
    childComponentsLoaded++;
    if (childComponentsLoaded >= childComponentsToLoad) {
      setTimeout(() => hideLoader(), 500);
    }
  }, false)

  const showEffect = (e: any) => {
    const value = e.detail.value;
    if (value.name === 'confetti') {
      if (value.active) {
        if (!isConfettiVisible) {
          setConfettiHiding(false);
          setConfettiVisible(true);
          myTimeout = setTimeout(() => setConfettiHiding(true), msTimeoutConfetti);
        }
      } else {
        setConfettiHiding(true);
      }
    }
    if (value.name === 'survey') {
      if (value.active) {
        setSurveyVisible(true);
        window.localStorage.setItem("showSurvey", "true");
      } else {
        setSurveyVisible(false);
        window.localStorage.setItem("showSurvey", "false");
      }
    }
  }

  return (
    userData &&
    <div className="live-page-wrapper container-fluid">
      {
        isConfettiVisible && <Confetti isHiding={isConfettiHiding} fnHide={() => setConfettiVisible(false)} />
      }
      <main className="live-page live-page--template-three">
        <div className="row live-page-templ-three-group-one">
          <div className="col-lg-8">
            <div className="mt-md-5">
              <div className="live-page-templ-three-title">
                <LivePageEventTitle />
              </div>
              <div className="live-page-templ-three-video">
                <LivePageVideo userData={userData} groupData={groupData} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-lg-0 mt-3">
            <div className='live-page-utility'>
              <br /><br />
              <br /><br />
              <br /><br />
            </div>
            <div className="live-page-templ-three-polls">
              <LivePagePolls />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8">
            <div className="live-page-templ-three-chat">
              <LivePageQuestionChat showMessages={false} />
            </div>
            <div className="live-page-templ-three-docs">
              <LivePageDocuments />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="live-page-templ-three-agenda">
              <LivePageAgenda userData={userData} groupData={groupData} />
            </div>
          </div>
        </div>
        {
          isSurveyVisible ?
            <div className="row mt-5">
              <div className="col-lg-8">
                <LivePageSectionHeader
                  title={translations.survey.survey_title[language]}
                  imgSource="assets/icons/survey.png"
                />
                <div className="live-survey">
                  <iframe src="https://forms.office.com/r/1FcJT9t5mh" />
                </div>
              </div>
            </div>
            :
            null
        }
      </main>
    </div>
  )

}

export default LivePageTemplateThree;