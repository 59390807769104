import React, { useEffect, useState } from 'react';


const EventHeader = () => {

    return (
        // <header className="event-header">
        //     <h1 className="event-header__title u-font-size-20">EMEA Town Hall – 2021 Kick Off</h1>
        //     <h2 className="event-header__subtitle u-font-size-12">Skill Enhancement - About</h2>
        //     <h3 className="event-header__date u-font-size-16">18 May, 10:30am – 12:00pm</h3>
        // </header>
        <header className="event-header">
            <h1 className="event-header__title">
                <div className="row">
                {/*
                <div className="col-lg-4 col-md-12 text-center img-fluid">
                    <img src='/assets/images/logo-summer-edition.png' alt="" />
                </div>
                */}
                <div className="col event-header__title__words">
                    <p className="u-font-size-50 mb-0"><b>EMEA</b> 2024</p>
                    <p className="u-font-size-40">Town Hall</p>
                </div>
                </div>
            </h1>
            <hr className="event-header__separator" />
            {/* <h2 className="event-header__subtitle u-font-size-25">
                2021 Kick-Off
            </h2> */}
            <h3 className="event-header__date">
                <p className="event-header__date-day u-font-size-22">3<sup>rd</sup> DECEMBER 2024</p>
                <p className="event-header__date-time u-font-size-15">01:00pm - 02:00pm CET</p>
            </h3>
        </header>
    )

}

export default EventHeader;