import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import axios from '../../config/axios';
import history from '../../config/history';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';

import Button from '../_UI/Button/Button';
import GalleryModal from '../Gallery/GalleryModal';
import { hideLoader, showLoader } from '../../config/functions';
import { Dropdown } from 'react-bootstrap';


interface IProps {
    userData: MembersTypeRes.IMember,
    groupData: MembersTypeRes.IGroupAssociation | null,
    setToken: (token: string | null) => void
}

const Navigation = ({ userData, groupData, setToken }: IProps) => {

    const [base64ProfileImage, setBase64ProfileImage] = useState<string>('');
    const [isGalleryVisible, showGallery] = useState<boolean>(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const { language } = useContext(ContextAppSettings);

    // const smallScreen = window.matchMedia('(max-width: 600px)').matches;

    useEffect(() => {
        const profilePictureID = userData.profilePictureID;
        if (profilePictureID && profilePictureID !== null) {
            axios.get('Resources/' + profilePictureID + '/base64?id=' + new Date().getTime())
                .then(res => {
                    if (res.data.status === "success")
                        setBase64ProfileImage(res.data.data.content)
                })
        } else {
            setBase64ProfileImage('')
        }
    }, [userData]);

    const downloadCsvViewers_03_December = () => {
        showLoader();

        const data: any = {
            startDate: new Date('2000-01-01'),
            endDate: new Date(),
            id: 20
        };
        axios.post('Stats/ContentViewersStatViewedAt', data).then(res => {
            const results: any = res.data.data;
            if (results && results.length > 0)
                download(results, null, "Viewers_03_December.csv", "text/csv;charset=UTF-8");
            else
                setModalOpen(true)
            hideLoader();
        })
    }

    const download = (data: any, header: any, filename: string, type: string) => {
        var fileData = convertJsonToCsv(data, header);
        // #IMPORTANT: utf-8 array to csv
        var file = new Blob(["\ufeff" + fileData], { type: type });
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    const convertJsonToCsv = (json: any, header: any) => {
        const JOINCHAR = ';';
        console.log(json)
        var fields = Object.keys(json[0])
        var replacer = function (key: any, value: any) { return value === null ? '' : value }
        var csv = json.map(function (row: any) {
            return fields.map(function (fieldName) {
                return JSON.stringify(row[fieldName], replacer)
            }).join(JOINCHAR)
        })
        if (header) {
            //console.log(header)
            //var strFields = Object.values(header).sort((a:any, b:any) => (a.orderNum > b.orderNum) ? 1 : -1).join(JOINCHAR);
            var strFields = Object.values(header).join(JOINCHAR);
            csv.unshift(strFields) // add header column
        }
        else {
            var strFields = fields.join(JOINCHAR);
            csv.unshift(strFields) // add header column
        }

        return (csv.join('\r\n'))
    }

    const name = userData.name;
    // const lastName = userData.lastName;
    // const teamName = groupData ? groupData.description : '';

    const roles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue('Auth-roles');
    const isChatAdmin = roles ? roles.findIndex(obj => obj.roleDescription === "ChatAdmin") >= 0 : false;

    return (
        <nav className="navigation container-fluid" id="navigation">
            <Link to="/">
                <img src="/assets/images/logo-zimmer-biomet.png" className="navigation-logo img-fluid" alt="zimmer biomet" />
            </Link>
            <div className="navigation-profile d-none">
                {
                    base64ProfileImage ?
                        <div className="navigation-profile__img-wrapper">
                            <img
                                src={base64ProfileImage}
                                className="navigation-profile__img img-fluid"
                                alt="profile"
                            />
                        </div> :
                        <FontAwesomeIcon icon={faUser} className="navigation-profile__icon" />
                }
                <p className="navigation-profile__name font-weight-bold u-font-size-12">
                    Welcome {name}
                    {/* { groupData && <><br/>{ smallScreen ? "Your team is: " : "You are a member of team "} <span className="color-red u-font-size-14">{teamName}</span></> } */}
                </p>
            </div>
            <div className="navigation-buttons">
                <div className="navigation-buttons-sm-right">
                    <div className="navigation-buttons-sm-right--2">
                        {
                            window.userInRole("UserAdmin") || window.userInRole("UserEditor") ||
                                window.userInRole("UserApproval") || window.userInRole("UserSpecial") ||
                                window.userInRole("StatsSpecial") ?
                                <Dropdown className="btn-md mr-2">
                                    <Dropdown.Toggle variant="primary" id="dropdown-lang" className="btn-sm">
                                        Statistics
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            window.userInRole("StatsSpecial") &&
                                            <Dropdown.Item
                                                onClick={() => downloadCsvViewers_03_December()}>
                                                Viewers_03_December
                                            </Dropdown.Item>
                                        }
                                        {
                                            window.userInRole("UserAdmin") || window.userInRole("UserEditor") ||
                                                window.userInRole("UserApproval") || window.userInRole("UserSpecial") ?
                                                <Dropdown.Item
                                                    onClick={() => history.push('/users-list')}>
                                                    Users list
                                                </Dropdown.Item>
                                                :
                                                null
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                null
                        }
                        <Button
                            withClass={["blue", "small"]}
                            classes="btn-edit-profile"
                            clicked={() => history.push('/update-my-info')} >
                            {translations.navbar.edit_profile[language]}
                        </Button>
                        <Button
                            withClass={["blue", "small"]}
                            classes="btn-logout"
                            clicked={() => {
                                setToken(null);
                                setTimeout(() => window.storageRemoveItem("redirectTo"), 1000)
                            }}>
                            {translations.navbar.logout[language]}
                        </Button>
                    </div>
                </div>
            </div>

            {/* Gallery window */}
            {
                isGalleryVisible &&
                <GalleryModal isVisible={isGalleryVisible} fnHideModal={() => showGallery(false)} />
            }
        </nav>
    )

}

export default Navigation;