import React, { useContext } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import Button from '../_UI/Button/Button';

const AddToCalendar = () => {

    const { language } = useContext(ContextAppSettings);

    return (
        <div className="save-the-date">
            <img className="save-the-date__calendar" src="/assets/icons/add-to-calendar.png" alt="Add to calendar"/>
            <h2 className="save-the-date-title u-font-size-12">{ translations.save_the_date.header_title[language] }</h2>
            <p className="save-the-date-info u-font-size-10">{ translations.save_the_date.header_subtitle[language] }</p>
            <div className="save-the-date-buttons mt-3">
                <div className="link-wrapper link-wrapper--google">
                    <a href="https://www.addevent.com/event/qr24014497+google" target="_blank">
                        <Button 
                            withClass={["secondary"]}
                            classes="link-button"
                            imageIcon="\assets\icons\calendar-google.png"
                            iconPosition="left"
                        >Google</Button>
                    </a>
                </div>
                <div className="link-wrapper link-wrapper--apple">
                    <a href="https://www.addevent.com/event/qr24014497+apple" target="_blank">
                        <Button 
                            withClass={["secondary"]}
                            classes="link-button"
                            imageIcon="\assets\icons\calendar-apple.png"
                            iconPosition="left"
                        >iCalendar</Button>
                    </a>
                </div>
                <div className="link-wrapper link-wrapper--outlook">
                    <a href="https://www.addevent.com/event/qr24014497+outlook" target="_blank">
                        <Button 
                            withClass={["secondary"]}
                            classes="link-button"
                            imageIcon="\assets\icons\calendar-outlook.png"
                            iconPosition="left"
                        >Outlook</Button>
                    </a>
                </div>
            </div>
        </div>
    )

}

export default AddToCalendar;